import { defineAsyncComponent } from 'vue';

const Nomina = defineAsyncComponent(() => import(/* webpackChunkName: "MiTF" */ '@/app/gente/nominas/views/Nominas.vue'));
const DetalleNomina = defineAsyncComponent(() => import(/* webpackChunkName: "Nominas" */ '@/app/gente/nominas/views/DetalleNomina.vue'));

export default [
  {
    path: '/nominas',
    name: 'Nominas',
    component: Nomina,
    meta: {
      requiresAuth: true,
      group: 'gente',
      application: 'nomina',
    },
  },
  {
    path: '/nominas/detalle',
    name: 'DetalleNomina',
    component: DetalleNomina,
    meta: {
      requiresAuth: true,
      group: 'gente',
      application: 'DetalleNomina',
    },
  },
];
