export default {
  namespaced: true,
  state: {
    lastBeaconService: null,
  },
  mutations: {
    SET_LAST_BEACON_SERVICE(state, service) {
      state.lastBeaconService = service;
    },
    CLEAR_LAST_BEACON_SERVICE(state) {
      state.lastBeaconService = null;
    },
  },
};
