import { mapState } from 'vuex';
import {
  environmentURL,
} from '@/api';

const beaconMixin = {
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('services', ['searchedServices']),
  },
  methods: {
    beacon(event) {
      this.$store.commit('feedback/SET_LAST_BEACON_SERVICE', this.service);
      const isActive = process.env.NODE_ENV === 'production';
      const shouldSendBeacon = 'sendBeacon' in navigator
        && isActive === true
        && !!(event.type)
        && !!(this.token);
      if (shouldSendBeacon) {
        const formData = new FormData();
        const serviceId = this.service.identifier;
        formData.append('identifier', serviceId);
        formData.append('token', this.token);
        formData.append('eventtype', event.type);
        const searchIdArray = this.searchedServices.find(
          (el) => el.serviceId === serviceId,
        );
        const searchIdValue = searchIdArray !== undefined ? searchIdArray.searchId : null;
        if (searchIdValue) {
          formData.append('searchid', searchIdValue);
        }
        navigator.sendBeacon(
          `${environmentURL}/aulavirtual/portal-api/public/v2/event/beacon/`,
          formData,
        );
      }
    },
  },
};

export { beaconMixin as default };
